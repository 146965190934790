import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import Slider from 'react-slick'
import Apiservice from './Apiservice'

const NavigationBar = (chkOutGenre) => {

  const getGenres = ["Action", "Adventure", "Comedy", "Drama", "Horror", "Thriller", "Family", "Sci-Fi", "Sports", "Romance", "Crime"]

  const [searchedMovieList, setSearchedMovieList] = useState([])


  const settings3 = {
    className: "",
    dots: true, // Show pagination dots
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in milliseconds
    slidesToShow: 8, // Number of slides to show at once
    slidesToScroll: 8, // Number of slides to scroll at a time
    adaptiveHeight: true
  };  

  const logoSrc = "https://chalanachitralu.com/assets/images/chalanachitralu_logo.png";  


  return (
    <React.Fragment>
      <header>
      <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
              <Link className="navbar-brand text-warning" to="/" title="Chalanachitralu"><img src={logoSrc} alt="chalanachitralu" /></Link>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link  text-white active" aria-current="page" to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link  text-white active" aria-current="page" to="/about-us">About Us</Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle text-white" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Movies
                    </Link>
                    <ul className="dropdown-menu">
                      <li><Link className="nav-link" to="/now-playing">Now Playing</Link></li>
                      <li><Link className="nav-link" to="/latest-telugu-movies">Latest Telugu Movies</Link></li>
                      <li><Link className="nav-link" to="/upcoming-telugu-movies">Upcoming Telugu Movies</Link></li>
                      {/* <li><Link className="nav-link" to="/">Top Rated</Link></li> */}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle text-white" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                     Genre
                    </Link>
                    <ul className="dropdown-menu">
                      {
                        getGenres.map((item,index) => {
                          return <li key={item}><Link className="nav-link" to={`/genre/telugu-${item.toLowerCase()}-movies`} id={item}>Telugu {item} Movies</Link></li>
                        })
                      }
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link  text-white" to="/telugu-imdb-movies">Telugu IMDb Movies</Link>
                  </li>
              </ul>
              </div>
          </div>
      </nav>
          <div className="hero">
            <div className="diagonal-hero-bg">
                <div className="stars">
                    <div className="small"></div>
                    <div className="medium"></div>
                    <div className="big"></div>
                </div>
            </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default NavigationBar