import axios from "axios"

const LIVE_URL = "https://chalanachitralu.com/ccapi/"

class Apiservice{

    getAllMoviesByGenre(){
        return axios.get(LIVE_URL + "getallmovies.php")
    }

    getNowMovies(){
        return axios.get(LIVE_URL + "getnowmovies.php")
    }
    
    getImdbMovies(){
        return axios.get(LIVE_URL + "getimdbtelugumovies.php")
    }

    getamovie(id){
        return axios.get(LIVE_URL + "getamoviedetail.php?id=" + id)
    }
    
    getGenreMovies(genre){
        return axios.get(LIVE_URL + "getgenremovies.php?genre=" + genre)
    }
    
    getSearchedMovies(searchedMovie){
        return axios.get(LIVE_URL + "getsearchedmovies.php?searchedMovie=" + searchedMovie)
    }

    getCastDetail(castName){
        return axios.get(LIVE_URL + "getcastdetail.php?castName=" + castName)
    }

}

export default new Apiservice