import moment from "moment";

class CommonFunctions{
    getDateFormat(value){
        if(value){
            return moment(value).format("MMMM Do, YYYY")
        }

        return '';
    }

    getNewDateFormat(value){
        if(value){
            return moment(value).format("Do MMMM, YYYY")
        }

        return '';
    }

    getTimeFormat(value){
        if(value){
            const timeString = value
            const durationParts = timeString.split(":");
            const hours = parseInt(durationParts[0]);
            const minutes = parseInt(durationParts[1]);
            const formattedTime = `${hours}hrs ${minutes} minutes`;
            return formattedTime;
        }

        return '';
    }



    currentYear = new Date().getFullYear();

    truncateText(text, maxLength){
        if (text.length <= maxLength) {
          return text;
        }
        
        return `${text.substring(0, maxLength)}...`;
      };
}

export default new CommonFunctions