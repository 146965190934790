import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';

import Apiservice from '../components/Apiservice';
import { Link } from 'react-router-dom';
import CommonFunctions from '../components/CommonFunctions';
import { Rating } from 'react-simple-star-rating';
import { Helmet } from 'react-helmet';
import NavigationBar from '../components/NavigationBar';
import Footer from './Footer';
import KeywordsTag from './KeywordsTag';
import Marquee from 'react-fast-marquee';


const Homepage = () => {    
    
    const [moviesByGenre, setMoviesByGenre] = useState({});
    const [upcomingMovies, setUpcomingMovies] = useState([])
    const [weeklyMovies, setWeeklyMovies] = useState([])
    const [monthlyMovies, setMonthlyMovies] = useState([])
    const [latestMovies, setLatestMovies] = useState([])
    const [pastMovies, setPastMovies] = useState([])
    const [celebsBdays, setCelebsBdays] = useState([])
    const [boxOffice, setBoxOffice] = useState([])
    const [moviesRandom, setMoviesRandom] = useState([])
    const [movieTrailers, setMovieTrailers] = useState([])
    const [dialogue, setDialogue] = useState('');
    const [loading, setLoading] = useState(true);    
    
    useEffect(() => {

        const getDialogueForDay = () => {
            const currentDate = new Date().toISOString().split('T')[0]; // Get the current date in the format 'YYYY-MM-DD'
            const storedDialogue = localStorage.getItem(currentDate);
            return storedDialogue ? JSON.parse(storedDialogue) : null; 
        };
        const storeDialogueForDay = (dialogue) => {
            const currentDate = new Date().toISOString().split('T')[0];
            localStorage.setItem(currentDate, JSON.stringify(dialogue));
        };

        Apiservice.getAllMoviesByGenre().then(
            (response) => {
                setMoviesByGenre(response.data.allGenres)                
                setUpcomingMovies(response.data.upcomingMovies)
                setWeeklyMovies(response.data.weeklyMovies)
                setMonthlyMovies(response.data.monthlyMovies)
                setPastMovies(response.data.pastMovies)
                setLatestMovies(response.data.latestMovies)  
                setCelebsBdays(response.data.celebsBdays)  
                setBoxOffice(response.data.boxOffice) 
                setMoviesRandom(response.data.randomMovies) 
                setMovieTrailers(response.data.trailerMovies) 
                setLoading(false);

                const fetchDialogue = async () => {
                    const storedDialogue = getDialogueForDay();
            
                    if (storedDialogue) {
                        // Use the stored dialogue if available
                        setDialogue(storedDialogue);
                    } else {
                        const newDialogue = response.data.dialogueMovies.dialogue;             
                        setDialogue(newDialogue);
                        storeDialogueForDay(newDialogue);           
                    }
                }

                
                fetchDialogue();
            }
        )
       
    },[])

    const settings = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 8, // Number of slides to show at once
        slidesToScroll: 8, // Number of slides to scroll at a time
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768, // adjust this value based on your design
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 1,
                },
              },
            {
              breakpoint: 480, // adjust this value based on your design
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            // add more responsive settings if needed
          ],
      };

    const settings1 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 3, // Number of slides to show at once
        slidesToScroll: 3, // Number of slides to scroll at a time
        adaptiveHeight: true,
        responsive: [
            {
                breakpoint: 768, // adjust this value based on your design
                settings: {
                  slidesToShow: 4,
                  slidesToScroll: 1,
                },
              },
            {
              breakpoint: 480, // adjust this value based on your design
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            // add more responsive settings if needed
          ],
      };
    const settings3 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 12, // Number of slides to show at once
        slidesToScroll: 12, // Number of slides to scroll at a time
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 768, // adjust this value based on your design
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480, // adjust this value based on your design
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            // add more responsive settings if needed
          ],
      };

    const imageUrl = "https://chalanachitralu.com/assets"   
    const defaultImageSrc = "https://chalanachitralu.com/assets/images/thumbs/dummy_movie-poster_cc.webp"   

    const schemaMarkup = {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": "http://www.chalanachitralu.com",
        "name": "Chalanachitralu",
        "description": "Check out Chalanachitralu.com for everything Telugu movies! Stay in the loop with the newest and upcoming releases, catch up on celebrity birthdays, watch trailers, and dive into in-depth details about your favorite films. Explore information about songs, ratings, and more. It's your go-to spot for all things related to Telugu cinema!",
        "publisher": {
            "@type": "Organization",
            "name": "Chalanachitralu",
            "logo": {
                "@type": "ImageObject",
                "url": "https://www.chalanachitralu.com/assets/images/chalanachitralu_logo_600x60.png"
            }
        }
    }
    

  return (
    <React.Fragment>
        <Helmet>
            <title>Chalanachitralu | A Telugu Movies Database Website</title>            
            <meta name="description" content="Chalanachitralu is the ultimate database for Telugu movies. Find detailed information on Telugu films, including cast, crew, release dates, reviews, and more. Stay updated with the latest news and insights in the Telugu cinema industry." />
            <meta name="keywords" content="Telugu movies, Telugu cinema, Telugu films, Chalanachitralu, Tollywood, Telugu movie database, Telugu film reviews, Telugu movie cast, Telugu movie crew, Telugu movie release dates, Telugu movie news, Telugu film industry, Chalanachitralu" />
            <meta name="author" content="Chalanachitralu" />
            <meta name="robots" content="index, follow" />
            <link rel="canonical" href="https://chalanachitralu.com" />
            <meta property="og:title" content="Chalanachitralu | A Telugu Movies Database" />
            <meta property="og:description" content="Explore the comprehensive database of Telugu movies with Chalanachitralu. Get detailed information on your favorite Telugu films, including cast, crew, release dates, and reviews." />
            <meta property="og:image" content="https://chalanachitralu.com/assets/images/chalanachitralu.png" />
            <meta property="og:url" content="https://chalanachitralu.com" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Chalanachitralu" />            
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
        </Helmet>
        <NavigationBar />
        {loading ? (
            <div className="preloader">
                <div className='loader'></div>
            </div>
        ) : 
            <React.Fragment>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='brl fs-5 text-white' title='Watch Latest Telugu Movies 2024'><i class="bi bi-play-circle"></i> Now Playing In-Theatres</h1>
                        <div className="slider-container">
                        <Slider {...settings3}>
                            {latestMovies.map((movie, index) => (
                                <div key={index}>
                                    <figure>
                                        <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >
                                            <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={`${movie.moviename} Telugu Movie`} className='img-fluid thumbImg' decoding="async" />
                                        </Link>
                                    </figure>
                                    <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="14" 
                                                        transition
                                                        allowFraction
                                                    />
                                    <h3  className='fs-6 mb-0 text-white text-truncate'><Link className='text-decoration-none text-info' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >{movie.moviename}</Link></h3>
                                    <p className='fs-6 mb-0  text-white text-truncate'> <small>{movie.genre}</small></p>
                                </div>
                            ))}
                        </Slider>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-md-9'>
                    <main>
                        <div className='trailers-scroller'>
                            <Marquee pauseOnHover={true} speed={75}>
                                <ul>
                                    {movieTrailers.map((movie, index) => (
                                        <li key={index}><i class="bi bi-film"></i> <Link className='text-decoration-none text-white' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`Watch ${movie.moviename} Telugu Movie Trailer`} >{movie.moviename}</Link></li>
                                    ))}
                                </ul>
                            </Marquee>
                        </div>
                        {Object.keys(moviesByGenre).map((genre) =>  ( 
                            <div key={genre} className="my-4">
                                <h2 className='brl fs-5 text-white' title={`Telugu ${genre} Movies`}>{genre} Movies <span className='float-end'><Link className='fs-6 text-info' to={`/genre/telugu-${(genre).toLowerCase()}-movies`}>more..</Link></span></h2>
                                <Slider {...settings}>
                                {moviesByGenre[genre].map((movie, index) => (
                                    <div key={index}>
                                        <figure>
                                            <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  title={`${movie.moviename} Telugu Movie`} >
                                                <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt="default_poster" className='img-fluid thumbImg' decoding="async" />
                                            </Link>
                                        </figure>
                                        <div className='card-body'>
                                            <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="14" 
                                                        transition
                                                        allowFraction
                                                    />
                                            <h3  className='fs-6 mb-0 text-white text-truncate'><Link className='text-decoration-none text-info' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  title={`${movie.moviename} Telugu Movie`} >{movie.moviename}</Link></h3>
                                            <p className='fs-6 mb-0 text-white text-truncate'> <small>{movie.genre}</small></p>
                                        </div>    
                                    </div>
                                ))}
                                </Slider>
                            </div>
                        ))}

                    <div className='card mt-4 mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1"><i className="bi bi-shuffle"></i> Random Telugu Movies</h2>
                        </div>
                        <div className='card-body '>
                            <div className='row'>
                                {moviesRandom.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <figure>
                                                    <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  title={`${movie.moviename} Telugu Movie`} >
                                                        <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}} decoding="async" />                                        
                                                    </Link>
                                                </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  title={`${movie.moviename} Telugu Movie`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.truncateText(movie.genre, 18)}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    </main>
                    </div>
                    <div className='col-md-3'>                
                        <div className='card mb-2 border-0 shadow'>
                            <div className='card-header bg-pcolor text-white'>
                                <h2 className="fs-6 my-1" title='Telugu Movie Dialogue'><i class="bi bi-megaphone-fill"></i> Telugu Movie Dialogue of the Day</h2>
                            </div>
                            <div className='card-body p-0'>
                                <ul className="list-group rounded-0">
                                    <li className="list-group-item  bg-pcolor text-white border-0">
                                        <div className="d-flex">
                                            <div className="flex-grow-1">                                                
                                                <p className='fs-6 mb-0'>{dialogue}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className='card mb-2'>
                            <div className='card-header'>
                            <h2 className="fs-6 my-1" title='Upcoming Telugu Movies'><i class="bi bi-calendar3"></i> Upcoming Telugu Movies {CommonFunctions.currentYear}</h2>
                            </div>
                            <div className='card-body p-0'>
                                <ul className="list-group rounded-0">

                                    {upcomingMovies.map((movie, index) => (
                                        <li className="list-group-item">
                                            <div key={index} className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <figure>
                                                        <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >
                                                            <img src={imageUrl+ "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt="default_poster" className='img-fluid thumbImg2' decoding="async" />
                                                        </Link>
                                                    </figure>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  title={`${movie.moviename} Telugu Movie`} >{CommonFunctions.truncateText(movie.moviename, 20)}</Link></h3>
                                                    <p className='fs-6 mb-0 text-truncate'> <small title={movie.genre}>{CommonFunctions.truncateText(movie.genre, 20)}</small></p>
                                                    <p className='fs-6 mb-0 text-truncate'><span><small className='text-muted'>Movie Release Date:</small></span> <br/> <small>{CommonFunctions.getNewDateFormat(movie.releasedate)}</small></p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        {(celebsBdays == null) ?
                        
                        <div className='card mb-2'>
                            <div className='card-header'>
                            <h2 className="fs-6 my-1" title='Celebrity Birthday Today'>Celebrity Today Birthday's</h2>
                            </div>
                            <div className='card-body '>
                                <Slider {...settings1} className="mb-2">
                                    {celebsBdays.map((movie, index) => (
                                        <div key={index}>
                                            <img src={imageUrl + "/images/poster.png"} alt={movie.cast_name} title={movie.cast_name} className='img-fluid' decoding="async" />
                                            <h3  className='fs-6 mb-0 text-truncate'>{movie.cast_name}</h3>                                        
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                        : ""}

                        <div className='card mb-2'>
                            <div className='card-header'>
                                <h2 className="fs-6 my-1" title='Box office collections telugu movies'><i class="bi bi-currency-rupee"></i> Box Office Collections Telugu Movies {CommonFunctions.currentYear}</h2>
                            </div>
                            <div className='card-body p-0'>
                                <ul className="list-group rounded-0">

                                    {boxOffice.map((movie, index) => (
                                        <li className="list-group-item">
                                            <div key={index} className="d-flex">
                                                <div className="flex-shrink-0">
                                                <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  title={`${movie.moviename} Telugu Movie`} >
                                                    <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} className='img-fluid thumbImg2' decoding="async" />
                                                </Link>
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >{CommonFunctions.truncateText(movie.moviename, 20)}</Link></h3>
                                                    <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                                    <p className='fs-6 mb-0 text-truncate'> <small className='badge bg-primary rounded-pill'>{movie.box_office} Crores</small></p>
                                                    <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="18" 
                                                        transition
                                                        allowFraction
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                        <div className='card mb-2'>
                            <div className='card-body p-0'>
                                <div className='card-header'>
                                    <i class="bi bi-graph-up-arrow"></i> New Telugu Movies Released
                                </div>
                                <ul className="nav nav-pills px-3" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">This Week</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">This Month</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tabContent">
                                    <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
                                        <ul className="list-group rounded-0">
                                            {weeklyMovies.map((movie, index) => (
                                                <li className="list-group-item">
                                                    <div key={index} className="d-flex">
                                                        <div className="flex-shrink-0">
                                                        <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >
                                                            <img src={imageUrl+ "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} className='img-fluid thumbImg2' decoding="async" />
                                                        </Link>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >{CommonFunctions.truncateText(movie.moviename, 20)}</Link></h3>
                                                            <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                                            <p className='fs-6 mb-0 text-truncate'><span><small className='text-muted'>Movie Release Date:</small></span> <br/> <small>{CommonFunctions.getNewDateFormat(movie.releasedate)}</small></p>
                                                            <Rating 
                                                                initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                                readonly 
                                                                size="18" 
                                                                transition
                                                                allowFraction
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabIndex="0">
                                        <ul className="list-group rounded-0">
                                            {monthlyMovies.map((movie, index) => (
                                                <li className="list-group-item">
                                                    <div key={index} className="d-flex">
                                                        <div className="flex-shrink-0">
                                                        <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >
                                                            <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} className='img-fluid thumbImg2' decoding="async" />
                                                        </Link>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3">
                                                            <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >{movie.moviename}</Link></h3>
                                                            <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                                            <p className='fs-6 mb-0 text-truncate'><span><small className='text-muted'>Movie Release Date:</small></span> <br/> <small>{CommonFunctions.getNewDateFormat(movie.releasedate)}</small></p>
                                                            <Rating 
                                                                initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                                readonly 
                                                                size="18" 
                                                                transition
                                                                allowFraction
                                                            />
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card mb-2'>
                            <div className='card-header'>
                            <h2 className="fs-6 my-1"><i class="bi bi-clock-history"></i> Today Released Past Movies</h2>
                            </div>
                            <div className='card-body p-0'>
                                <ul className="list-group rounded-0">

                                    {pastMovies.map((movie, index) => (
                                        <li className="list-group-item">
                                            <div key={index} className="d-flex">
                                                <div className="flex-shrink-0">
                                                    <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} style={{width: '65px'}} decoding="async" />
                                                </div>
                                                <div className="flex-grow-1 ms-3">
                                                    <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} title={`${movie.moviename} Telugu Movie`} >{CommonFunctions.truncateText(movie.moviename, 20)}</Link></h3>
                                                    <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                                    <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="18" 
                                                        transition
                                                        allowFraction
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <KeywordsTag />
            
            <Footer />
            </React.Fragment>
            }
        
    </React.Fragment>
  )
}

export default Homepage