import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Apiservice from '../components/Apiservice'
import NavigationBar from '../components/NavigationBar'
import { Rating } from 'react-simple-star-rating'
import Slider from 'react-slick'
import CommonFunctions from '../components/CommonFunctions'
import { Helmet } from 'react-helmet'
import Footer from './Footer'

const NowMoviesPage = () => {

    const [upcomingMovies, setUpcomingMovies] = useState([])
    const [latestMovies, setLatestMovies] = useState([])
    const [pastMovies, setPastMovies] = useState([])
    const [celebsBdays, setCelebsBdays] = useState([])
    const [boxOffice, setBoxOffice] = useState([])
    const [movieRating, setMovieRating] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 22; // Number of items to display per page
   
    
    useEffect(() => {
      Apiservice.getNowMovies().then((response) => {
        setUpcomingMovies(response.data.upcomingMovies)  
        setLatestMovies(response.data.latestMovies)  
        setPastMovies(response.data.pastMovies)  
        setCelebsBdays(response.data.celebsBdays)  
        setBoxOffice(response.data.boxOffice) 
      });

     
      }, []);


    const tooltipArray = [
        "Terrible",
        "Terrible+",
        "Bad",
        "Bad+",
        "Average",
        "Average+",
        "Great",
        "Great+",
        "Awesome",
        "Awesome+"
      ];
      

      const opts = {
        height: '200',
        width: '100%',
        playerVars: {
          autoplay: 0,
        },
      };

      const imageUrl = "https://chalanachitralu.com/assets"   
      const defaultImageSrc = "https://chalanachitralu.com/assets/images/thumbs/dummy_movie-poster_cc.webp"   

      const MoviePoster = ({  }) => {      
        if (latestMovies && latestMovies.moviename) {
          const formattedMoviename = latestMovies.moviename.toLowerCase().replace(/[ ,_\/]/g, '-');
          const imageSrc = imageUrl + `/images/thumbs/${formattedMoviename}-telugu_movie-poster_cc.webp`;
      
          return (
            <img
              src={imageSrc}
              onError={(e) => {
                e.target.src = defaultImageSrc; 
              }}
              alt="default_poster"
              className='img-fluid'
            />
          );
        } else {         
          return (
            <img
              src={defaultImageSrc}
              alt="default_poster"
              className='img-fluid'
            />
          );
        }
    }

    const getTheGenre= <span className='text-capitalize'></span>

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = latestMovies.slice(indexOfFirstItem, indexOfLastItem);
  
    const totalPages = Math.ceil(latestMovies.length / itemsPerPage);
  
    const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
    };

    const settings1 = {
      className: "",
      dots: true, // Show pagination dots
      infinite: true, // Infinite loop
      speed: 500, // Transition speed in milliseconds
      slidesToShow: 6, // Number of slides to show at once
      slidesToScroll: 6, // Number of slides to scroll at a time
      adaptiveHeight: true
    };      

    const settings2 = {
      className: "",
      dots: true, // Show pagination dots
      infinite: true, // Infinite loop
      speed: 500, // Transition speed in milliseconds
      slidesToShow: 4, // Number of slides to show at once
      slidesToScroll: 4, // Number of slides to scroll at a time
      adaptiveHeight: true
    };      

    const settings3 = {
      className: "",
      dots: true, // Show pagination dots
      infinite: true, // Infinite loop
      speed: 500, // Transition speed in milliseconds
      slidesToShow: 12, // Number of slides to show at once
      slidesToScroll: 12, // Number of slides to scroll at a time
      adaptiveHeight: true
    };  

  return (
    <React.Fragment>
         <Helmet>
            <title>New Telugu Movies in Theatres | Chalanachitralu</title>
            <meta name="description" content="Stay updated with the latest new telugu movies. Check out new releases, ratings, trailers, and more on Chalanachitralu." />
            <meta name="keywords" content="New telugu movies, Latest telugu movie releases, New telugu Movies, Telugu movies in theatres, Chalanachitralu" />
            <meta name="author" content="Chalanachitralu.com" />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content="New Telugu Movies in Theatres | Chalanachitralu" />
            <meta property="og:description" content="Stay updated with the latest new telugu movies in theatres. Check out new releases, ratings, trailers, and more on Chalanachitralu." />
            <meta property="og:image" content="https://chalanachitralu.com/assets/images/chalanachitralu.png" />
            <meta property="og:url" content="https://chalanachitralu.com/latest-telugu-movies" />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="Chalanachitralu" />
            <script type="application/ld+json">
            {JSON.stringify({
                "@context": "https://schema.org",
                "@type": "ItemList",
                "name": "New Telugu Movies in Theatres",
                "description": "Stay updated with the latest new telugu movies in theatres. Check out new releases, ratings, trailers, and more on Chalanachitralu.",
                "url": "https://chalanachitralu.com/now-playing",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://chalanachitralu.com/now-playing"
                },
                "image": "https://chalanachitralu.com/assets/images/chalanachitralu.png"
            })}
            </script>
        </Helmet>
        <div className='ht-adj'>
        <NavigationBar />
        <nav aria-label="breadcrumb" className='mt-2'>
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
            <li className="breadcrumb-item active  text-info" aria-current="page">Now Playing - Telugu Movies In-Theatres in {CommonFunctions.currentYear}</li>
          </ol>
        </div>
      </nav>
        <div className="container-fluid">
            <div className="row">                
                <div className='col-md-9'>
                    <h1 className='fs-4 text-white'>New Telugu Movies Released and Now Playing in Theatres</h1>

                    {currentItems.length === 0 ? (
                        <div className='row'>
                            <div className='col-md-12'>
                                <div className="alert alert-danger" role="alert">
                                    <p className='mb-0'>Loading...</p>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <React.Fragment>
                        <div className="row g-2 mb-2">
                            {currentItems.map((item, id) => (
                                <div className="col-sm-2 col-md-2 col-lg-2">
                                    <div className="card" key={id}>
                                        <img src={imageUrl + "/images/thumbs/" + (item.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={item.moviename} title={item.moviename} className='img-fluid thumbImg3' />
                                        <div className="card-body p-1">
                                        <h2 className="fs-6 card-title text-truncate mb-0"><Link className='text-decoration-none' to={`/telugu-movie/${item.id}/${(item.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} onClick={()=>sessionStorage.setItem("movieId", item.id)} >{item.moviename}</Link></h2>
                                        <Rating 
                                              initialValue={(item.rating != '') ? item.rating : '2.5'} 
                                              readonly 
                                              size="16" 
                                              transition
                                              allowFraction
                                          />
                                        <p className='fs-6 mb-0 text-truncate'><span><small className='text-muted'>Movie Released On:</small></span> <br/> <small>{CommonFunctions.getNewDateFormat(item.releasedate)}</small></p>
                                        </div>
                                    </div>
                                </div>
                                ))}
                        </div>
                        <nav>
                            <ul className="pagination">
                                {Array.from({ length: totalPages }, (_, index) => (
                                <li
                                    key={index}
                                    className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}
                                >
                                    <button
                                    className="page-link"
                                    onClick={() => handlePageChange(index + 1)}
                                    >
                                    {index + 1}
                                    </button>
                                </li>
                                ))}
                            </ul>
                        </nav>
                    </React.Fragment>
                    )
                }

                    
                    <div className='card'>
                        <div className='card-header'>
                            <h3 className='fs-5'>Today released past movies</h3>
                        </div>
                        <div className='card-body mb-2'>
                            <div className='row'>
                                {pastMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                            <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}} />                                        
                                            </Link>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Upcoming Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body p-0'>
                        <ul className="list-group rounded-0">
                            {upcomingMovies.map((movie, index) => (
                                <li className="list-group-item">
                                    <div key={index} className="d-flex">
                                        <div className="flex-shrink-0">
                                        <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt="default_poster" className='img-fluid thumbImg2' />
                                        </div>
                                        <div className="flex-grow-1 ms-3">
                                        <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename,18)}</Link></h3>
                                                <p className='fs-6 mb-0 text-truncate'> <small title={movie.genre}>{CommonFunctions.truncateText(movie.genre,25)}</small></p>
                                            <p className='fs-6 mb-0 text-truncate'><span><small className='text-muted'>Movie Release Date:</small></span> <br/> <small>{CommonFunctions.getNewDateFormat(movie.releasedate)}</small></p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            </ul>
                        </div>
                    </div>
                    
                    {(celebsBdays == null) ?                    
                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Celebrity Today Birthday's</h2>
                        </div>
                        <div className='card-body '>
                            <Slider {...settings2} className="mb-2">
                                {celebsBdays.map((movie, index) => (
                                    <div key={index}>
                                        <img src={imageUrl + "/images/cast/" + (movie.cast_name).toLowerCase().replace(/[ ,_\/]/g, '-') + "_poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.cast_name} title={movie.cast_name} className='img-fluid' />
                                        <h3  className='fs-6 mb-0 text-truncate'>{movie.cast_name}</h3>                                        
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    : "" }

                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Box Office Collections Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body p-0'>
                            <ul className="list-group rounded-0">

                                {boxOffice.map((movie, index) => (
                                    <li className="list-group-item">
                                        <div key={index} className="d-flex">
                                            <div className="flex-shrink-0">
                                            <img src={imageUrl+ "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='img-fluid thumbImg2' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                            <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename,25)}</Link></h3>
                                                <p className='fs-6 mb-0 text-truncate'> <small title={movie.genre}>{CommonFunctions.truncateText(movie.genre,25)}</small></p>
                                                <p className='fs-6 mb-0 text-truncate'> <small className='badge bg-primary rounded-pill'>{movie.box_office} Crores</small></p>
                                                <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="18" 
                                                        transition
                                                        allowFraction
                                                    />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </React.Fragment>
  )
}

export default NowMoviesPage