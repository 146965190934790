import React from "react";
import { Helmet } from 'react-helmet'
import { Link } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Footer from "./Footer";
import KeywordsTag from "./KeywordsTag";

const Aboutpage = () => {
    return(
        <React.Fragment>
            <Helmet>
                <title>About Us | Chalanachitralu</title>
                <meta name="description" content="Learn more about Chalanachitralu, your ultimate destination for Telugu movie information, reviews, ratings, and more." />
                <meta name="keywords" content="About Chalanachitralu, Telugu movies, movie database, about us" />
                <meta name="author" content="Chalanachitralu.com" />
                <meta name="robots" content="index, follow" />
                <meta property="og:title" content="About Us | Chalanachitralu" />
                <meta property="og:description" content="Learn more about Chalanachitralu, your ultimate destination for Telugu movie information, reviews, ratings, and more." />
                <meta property="og:image" content="https://www.chalanachitralu.com/assets/images/chalanachitralu_logo_600x60.png" />
                <meta property="og:url" content="https://chalanachitralu.com/about-us" />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="Chalanachitralu" />
                <script type="application/ld+json">
                {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "AboutPage",
                    "name": "About Us",
                    "description": "Learn more about Chalanachitralu, your ultimate destination for Telugu movie information, reviews, ratings, and more.",
                    "url": "https://chalanachitralu.com/about-us",
                    "mainEntityOfPage": {
                        "@type": "WebPage",
                        "@id": "https://chalanachitralu.com/about-us"
                    },
                    "image": "https://www.chalanachitralu.com/assets/images/chalanachitralu_logo_600x60.png"
                })}
                </script>
            </Helmet>

            <div className='ht-adj'>
                <NavigationBar />
                <nav aria-label="breadcrumb" className='mt-2'>
                    <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
                        <li className="breadcrumb-item active  text-info" aria-current="page">About Us</li>
                    </ol>
                    </div>
                </nav>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">       
                            <div className="card">       
                                <div className="card-body">
                                    <h1 className="fs-2 mt-0 mb-4">About Us - ChalanaChitralu</h1>       
                                    <p>Chalanachitralu website is a telugu movies database website, a platform that provides comprehensive information about movies made in the Telugu language, which is primarily spoken in the Indian states of Andhra Pradesh and Telangana. Chalanachitralu serves as repositories of data related to Telugu films, including details about actors, directors, producers, music composers, release dates, box office collections, reviews, and other related information.</p>
                                    <p>The main purpose of Chalanachitralu website is to offer a centralized source of information for Telugu cinema enthusiasts, industry professionals, and moviegoers. Users can access the website to explore a vast collection of Telugu movies, search for specific films or artists, and gather various details associated with them.</p>
                                    <p>Typically, these Chalanachitralu website is a user-friendly interface with different sections or categories to navigate through and included search functions, filters, and sorting options to facilitate easy browsing. Users can search for movies by title using the dynamic movie search in the navigation menu. Upon selecting a particular movie, the Chalanachitralu provides a dedicated page with detailed information, such as cast and crew, plot synopsis, production details, trivia, songs, trailers, and user reviews.</p>
                                    <p>Overall, Chalanachitralu, a Telugu movies database website serves as a comprehensive platform that caters to the needs of Telugu cinema enthusiasts by providing them with a wealth of information about Telugu movies, making it easier for them to explore, discover, and appreciate the rich cinematic heritage of the Telugu film industry.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <KeywordsTag />
            <Footer />
        </React.Fragment>
    )
}
export default Aboutpage