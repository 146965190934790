import React from "react";
import { Helmet } from 'react-helmet'
import { Link } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Footer from "./Footer";

const PrivacyPolicy = () => {
    return(
        <React.Fragment>
               <Helmet>
                    <title>Privacy Policy | Chalanachitralu</title>
                </Helmet>
                <div className='ht-adj'>
                <NavigationBar />
                <nav aria-label="breadcrumb" className='mt-2'>
                <div className="container-fluid">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
                    <li className="breadcrumb-item active  text-info" aria-current="page">Privacy Policy</li>
                </ol>
                </div>
            </nav>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                                <h1 className="fs-2 mt-0 mb-4">Privacy Policy</h1> 
                                <p>This privacy policy sets out how chalanachitralu.com uses and protects any information that you give chalanachitralu.com when you use this website.</p>
                                <p>chalanachitralu.com is committed to ensure that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.</p>
                                <p>chalanachitralu.com may change this policy from time to time by updating this page. You should check this page time to time to ensure that you are happy with any changes.</p>
                                <p><strong>What we collect</strong><br/>
                                    We may collect the following information from you:<br/>
                                    <ul>
                                        <li>
                                            We use Google Analytics for website visitors information (data includes device detials, geographical data)
                                        </li>
                                        <li>
                                            We may collect your IP Address, Browser information, or location information automatically while submitting a form, but shall be informed to you in the same form, before you fill in the details and send the form.
                                        </li>
                                    </ul>
                                </p>
                                <p><strong>What we do with the information we gather</strong><br/>
                                    We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:<br/>
                                    <ul>
                                        <li>Internal record keeping</li>
                                        <li>We may use the information to improve our products and services</li>
                                        <li>We may periodically send promotional email about new movies, new songs, new albums, special events, special offers or other information which we think you may find interesting using the email address which you have provided</li>
                                        <li>From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, or mail. However, we do not sell out your personal details to any chain marketing or multi-level marketing companies, Banks, share brokers and insurance companies</li>
                                        <li>We may use the information to customise the website according to your interests</li>
                                        <li>We do not disclose any personal information to an unrelated third party, except with the individual's consent or where we are required to do so by any court of law, or by any investigating officer in writing</li>
                                        <li>We take reasonable steps to destroy or de-identify personal information in a secure manner when we have no further need for it or are required to do so by law</li>
                                        <li>We ensure only authorised personnel who have agreed to keep personal information confidential have access to the information</li>
                                    </ul>
                                </p>
                                <p><strong>Links to other websites</strong><br/>
                                    Our website may contain links to enable you to visit other websites of interest easily. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.                        
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <Footer />
        </React.Fragment>
    )
}

export default PrivacyPolicy