import React, { useEffect, useState } from 'react'
import { Link, useParams, useLocation  } from 'react-router-dom'
import Apiservice from '../components/Apiservice'
import NavigationBar from '../components/NavigationBar'
import { Rating } from 'react-simple-star-rating'
import Slider from 'react-slick'
import CommonFunctions from '../components/CommonFunctions'
import YouTube from 'react-youtube'
import { Helmet } from 'react-helmet'
import Footer from './Footer'

const Detailspage = () => {
    const { id } = useParams();
    
    const [movieDetail, setMovieDetail] = useState([])
    const [upcomingMovies, setUpcomingMovies] = useState([])
    const [latestMovies, setLatestMovies] = useState([])
    const [pastMovies, setPastMovies] = useState([])
    const [celebsBdays, setCelebsBdays] = useState([])
    const [boxOffice, setBoxOffice] = useState([])
    const [heroMovies, setHeroMovies] = useState([])
    const [movieRating, setMovieRating] = useState('')
    const [celebrities, setCelebrities] = useState([])
    const [getMovieSongs, setGetMovieSongs] = useState([])
    const [movieNameUrl, setMovieNameUrl] = useState('')
    const [loading, setLoading] = useState(true);    

    useEffect( () => {
        const fetchData = async () => {
        try {
            const response = await Apiservice.getamovie(id)
            const result = await response.data;
            const getMovieName = result.movieData.moviename.toLowerCase().replace(/[ ,_\/]/g, '-');
                setMovieNameUrl(getMovieName)
                setMovieDetail(result.movieData)  
                const ratingString = result.movieData.rating;
                setMovieRating(ratingString)     
                setUpcomingMovies(result.upcomingMovies)  
                setLatestMovies(result.latestMovies)  
                setPastMovies(result.pastMovies)  
                setCelebsBdays(result.celebsBdays)  
                setBoxOffice(result.boxOffice)  
                setHeroMovies(result.heroMovie)   
                setGetMovieSongs(result.movieSongs)   
                
                const getcasts = (result.movieData.castname)
                setCelebrities(getcasts.split(','))
                setLoading(false);
            }catch (error) {
                console.error('Error fetching data:', error);
            }
        }
        fetchData();
    }, [id]);

    const location = useLocation();

    useEffect(() => {
      // Use the hash part of the URL to determine if a scroll position is specified
      const hash = location.hash;
  
      if (hash) {
        // Use the specified hash as an element ID and scroll to that element
        const targetElement = document.getElementById(hash.substring(1));
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        // Scroll to the top if no hash is present
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }, [location]);

    const tooltipArray = [
        "Terrible",
        "Terrible+",
        "Bad",
        "Bad+",
        "Average",
        "Good",
        "Superb",
        "Awesome",
        "Awesome+"
      ];

      const settings1 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 6, // Number of slides to show at once
        slidesToScroll: 6, // Number of slides to scroll at a time
        adaptiveHeight: true
      };      

      const settings2 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 4, // Number of slides to show at once
        slidesToScroll: 4, // Number of slides to scroll at a time
        adaptiveHeight: true
      };      

      const settings3 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 12, // Number of slides to show at once
        slidesToScroll: 12, // Number of slides to scroll at a time
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 768, // adjust this value based on your design
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480, // adjust this value based on your design
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            // add more responsive settings if needed
          ],
      };      

      const extractVideoIdFromUrl = (url) => {
        if (!url) {
        return null;
        }
        const videoIdMatch = url.match(/youtu\.be\/([a-zA-Z0-9_-]{11})/);
      
        if (videoIdMatch && videoIdMatch.length > 1) {
          return videoIdMatch[1];
        }
      
        return null;
      };
      

      const opts = {
        height: '200',
        width: '100%',
        playerVars: {
          autoplay: 0,
        },
      };

      const imageUrl = "https://chalanachitralu.com/assets"   
      const defaultImageSrc = "https://chalanachitralu.com/assets/images/thumbs/dummy_movie-poster_cc.webp"   
      
      const MoviePoster = ({  }) => {         
        if (movieDetail && movieDetail.moviename) {
          const formattedMoviename = movieDetail.moviename.toLowerCase().replace(/[ ,_\/]/g, '-');
          const imageSrc = imageUrl + `/images/thumbs/${formattedMoviename}-telugu_movie-poster_cc.webp`;
      
          return (
            <img
              src={imageSrc}
              onError={(e) => {
                e.target.src = defaultImageSrc; 
              }}
              alt="default_poster"
              className='img-fluid'
            />
          );
        } else {         
          return (
            <img
              src={defaultImageSrc}
              alt="default_poster"
              className='img-fluid'
            />
          );
        }
    }

    const formattedDate = CommonFunctions.getDateFormat(movieDetail.releasedate);
    const getCustomDescription = (movieDetail) => {
        const releaseDate = new Date(movieDetail.releasedate);
        const currentDate = new Date();

        if (releaseDate < currentDate) {
            return `${movieDetail.moviename} is a Telugu ${movieDetail.genre} movie, released on ${formattedDate}, directed by ${movieDetail.director} and produced by ${movieDetail.producer}.`;
        } else {
            return `${movieDetail.moviename} is a Telugu ${movieDetail.genre} movie, releasing on ${formattedDate}, directed by ${movieDetail.director} and produced by ${movieDetail.producer}.`;
        }
    };

    const customDescription = getCustomDescription(movieDetail);

    const schemaMarkup = {
        "@context": "https://schema.org",
        "@type": "Movie",
        "name": movieDetail.moviename,
        "genre": movieDetail.genre,
        "datePublished": movieDetail.releasedate,
        "description": customDescription,
        "aggregateRating": movieDetail.rating ? {
            "@type": "AggregateRating",
            "ratingValue": movieDetail.rating,
        } : undefined,
        "director": {
            "@type": "Person",
            "name": movieDetail.director
        },
        "actor": movieDetail.castname ? movieDetail.castname.split(',').map(name => ({
            "@type": "Person",
            "name": name.trim()
        })) : [],
        "image": `https://chalanachitralu.com/assets/images/thumbs/${movieNameUrl}-telugu_movie-poster_cc.webp`,
        "trailer": {
            "@type": "VideoObject",
            "name": `${movieDetail.moviename} Trailer`,
            "embedUrl": movieDetail.trailer_link1,
            "thumbnailUrl": `https://chalanachitralu.com/assets/images/thumbs/${movieNameUrl}-trailer_thumbnail.webp`
        },
        "url": `https://chalanachitralu.com/telugu-movie/${movieDetail.id}/${movieNameUrl}`,
    };


  return (

    <React.Fragment>
        <Helmet>
            <title>{`${movieDetail.moviename}`} Telugu Movie ({`${movieDetail.year}`}) Release Date, Cast, Songs, Ratings | Chalanachitralu</title>
            <meta name="description" content={`Explore detailed information about the Telugu movie '${movieDetail.moviename}' including genre, release date, synopsis, rating, cast and crew, songs, trailer, and more on Chalanachitralu.`} />
            <meta name="keywords" content={`${movieDetail.moviename}, ${movieDetail.moviename} movie, Telugu movie ${movieDetail.moviename}, ${movieDetail.moviename} cast, ${movieDetail.moviename} crew, ${movieDetail.moviename} release date, ${movieDetail.moviename} songs, ${movieDetail.moviename} trailer, Telugu movies, Tollywood`} />
            <meta name="author" content="Chalanachitralu.com" />
            <meta name="robots" content="index, follow" />
            <meta property="og:title" content={`${movieDetail.moviename} Telugu Movie ${movieDetail.year} Release Date, Cast, Songs, Ratings | Chalanachitralu`} />
            <meta property="og:description" content={`Find comprehensive details about the Telugu movie '${movieDetail.moviename}' including its genre, release date, synopsis, rating, cast and crew, songs, and trailer on Chalanachitralu.`} />
            <meta property="og:image" content={`https://chalanachitralu.com/assets/images/thumbs/${movieNameUrl}-telugu_movie-poster_cc.webp`} />
            <meta property="og:url" content={`https://chalanachitralu.com/telugu-movie/${movieDetail.id}/${movieNameUrl}`} />
            <meta property="og:type" content="video.movie" />
            <meta property="og:site_name" content="Chalanachitralu" />
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
        </Helmet>
        <div className='ht-adj'>
        <NavigationBar />
        {loading ? (
            <div className="preloader">
                <div className='loader'></div>
            </div>
        ) : 
        <React.Fragment>
        <nav aria-label="breadcrumb" className='mt-2'>
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
            <li className="breadcrumb-item text-white">Telugu Movie</li>
            <li className="breadcrumb-item active  text-info" aria-current="page">{movieDetail.moviename} Telugu Movie {movieDetail.year}</li>
          </ol>
        </div>
      </nav>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-9">
                    <div className="card mb-3">
                        <div className="row g-0">
                            <div className="col-md-3 col-lg-2">
                            <MoviePoster />
                            </div>
                            <div className="col-md-9 col-lg-10">
                            <div className="card-body">
                                <h1 className="card-title fs-4 text-uppercase">{movieDetail.moviename} <span className='fs-6'>({movieDetail.year})</span></h1>
                                <p className="card-text mb-2 fst-italic">{customDescription} The cast includes {movieDetail.castname}.</p>
                                <p className="card-text">
                                    {(movieDetail.duration != null && movieDetail.duration != "00:00:00") ? <React.Fragment><small className="text-secondary ">Duration: {CommonFunctions.getTimeFormat(movieDetail.duration)}</small>                                 
                                    <span className='px-2'>&#124;</span></React.Fragment> : ""}  
                                    <small className="text-secondary">Genre: {movieDetail.genre}</small>                                    
                                    <span className='px-2'>&#124;</span>
                                    <small className="text-secondary">Certificate: <span className='badge text-bg-warning'>{movieDetail.movie_cert}</span></small>                                    
                                    <span className='px-2'>&#124;</span>
                                    <small className="text-secondary">Release Date: {CommonFunctions.getDateFormat(movieDetail.releasedate)}</small>                                    
                                </p>
                                {
                                    (movieDetail.rating != '') ? 
                                    <React.Fragment>
                                        <Rating 
                                            initialValue={(movieRating != '') ? movieRating : '2.5'} 
                                            readonly 
                                            size="24" 
                                            transition
                                            allowFraction
                                            showTooltip
                                            tooltipArray={tooltipArray}
                                        />
                                        <br/>
                                        <small>(Movie Rating: {(movieRating != '') ? <span className='fw-bold'>{movieRating}</span> : '2.5'}/5)</small>
                                    </React.Fragment>
                                    : '' 
                                }
                                {(movieDetail.imdb_rating != null) ? <div className='imdbRating my-2'><span>IMDb</span> {movieDetail.imdb_rating}/10</div> : ''}
                            </div>
                            </div>
                        </div>
                    </div>
                    {(movieDetail.movierulz == '1' || movieDetail.ibomma == '1' || movieDetail.bolly2tolly == '1') ? 
                        <div className="alert alert-danger" role="alert">
                            <i className="bi bi-exclamation-triangle-fill"></i> <strong>{movieDetail.moviename}</strong> telugu movie is leaked on {(movieDetail.movierulz == '1') ? "MovieRulz website" : ""} {(movieDetail.movierulz == '1' && movieDetail.ibomma == '1') ? "and" : ''} {(movieDetail.ibomma == '1' ? "iBomma website" : "")}.
                        </div>
                    : ""}
                                                        
                    <div className='card mb-2'>
                        <div className='card-header'>
                            <h2 className="fs-6 my-1"><i className="bi bi-people-fill"></i> Cast &amp; Crew of {movieDetail.moviename} Telugu Movie</h2>
                        </div>
                        <div className='card-body p-0'>                            
                            <ul className="list-group">
                                <li className="list-group-item">Cast: <strong> 
                                    {
                                        (celebrities).map(
                                            (castnames, index) => (
                                                <span key={index}><Link className='text-decoration-none' to={`/celebrities/${castnames.toLowerCase().replace(/[ ,_\/]/g, '-')}`} onClick={()=>(sessionStorage.setItem("celebrity", castnames.trim()))} >{castnames}</Link>{index < celebrities.length - 1 && ','}  </span>
                                            )
                                        )
                                    }
                                </strong></li>
                                <li className="list-group-item">Director: <strong>{movieDetail.director}</strong></li>
                                {(movieDetail.producer !== '') ? <li className="list-group-item">Producer: <strong>{movieDetail.producer}</strong></li> : ''}
                                {(movieDetail.music_director !== '') ? <li className="list-group-item">Music By: <strong>{movieDetail.music_director}</strong></li> : ''}
                                {(movieDetail.banner !== '') ? <li className="list-group-item">Banner: <strong>{movieDetail.banner}</strong></li> : ''}
                            </ul>
                        </div>
                    </div>
                    
                    {(getMovieSongs != '') ?
                    <div className='card mb-2'>
                        <div className='card-header'>
                            <h2 className="fs-6 my-1"><i className="bi bi-music-note-list"></i> {movieDetail.moviename} Telugu Movie Songs</h2>
                        </div>
                        <div className='card-body p-0'>                            
                            <table className='table table-bordered table-striped'>
                                <thead>
                                    <tr>
                                        <th>Song No</th>
                                        <th>Song Name</th>
                                        <th>Singer Names</th>
                                        <th>Song Duration</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        (getMovieSongs).map(
                                            (songs, index) => (
                                                <tr key={index}>
                                                    <td>{songs.song_no}</td>
                                                    <td>{songs.song_title}</td>
                                                    <td>{songs.singers}</td>
                                                    <td>{songs.length}</td>
                                                </tr>
                                                )
                                                )
                                            }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    : ""}

                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1"><i className="bi bi-list-stars"></i> List of {movieDetail.hero} Telugu Movies</h2>
                        </div>
                        <div className='card-body '>
                            <div className='row'>
                                {heroMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                            <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}}  decoding="async" />                                        
                                            </Link>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    
                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1"><i className="bi bi-list-stars"></i> Latest Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body '>
                            <div className='row'>
                                {latestMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <figure>
                                                    <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                        <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}} />                                        
                                                    </Link>
                                                </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.truncateText(movie.genre, 18)}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className='card mb-2'>
                        <div className='card-header'><i className="bi bi-question-circle-fill"></i> Frequently Asked Questions</div>
                        <div className='card-body p-0'>
                            <div className="accordion" id="accordionFlushExample">
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                    What is the release date of {movieDetail.moviename} telugu movie?
                                </button>
                                </h2>
                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The release date of {movieDetail.moviename} telugu movie is on <strong>{CommonFunctions.getDateFormat(movieDetail.releasedate)}</strong>. </div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    Who are casts in {movieDetail.moviename} telugu movie?
                                </button>
                                </h2>
                                <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The casts in {movieDetail.moviename} telugu movie are <strong>{movieDetail.castname}</strong>.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                    Who is the Director of {movieDetail.moviename} Telugu Movie?
                                </button>
                                </h2>
                                <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">The director of {movieDetail.moviename} is {movieDetail.director}.</div>
                                </div>
                            </div>
                            <div className="accordion-item">
                                <h2 className="accordion-header">
                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                                    What is the review of {movieDetail.moviename} Telugu Movie?
                                </button>
                                </h2>
                                <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body">Chalanachitralu website has given {movieDetail.moviename} a <span className='badge text-bg-warning'>{movieDetail.rating}</span> stars out of <span className='badge text-bg-success'>5</span> star rating.</div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className='card mb-2'>
                        <div className='card-header'>
                            <h3 className='fs-5'>Today released past movies</h3>
                        </div>
                        <div className='card-body mb-2'>
                            <div className='row'>
                                {pastMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                                <figure>
                                                    <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                        <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}}  decoding="async" />                                        
                                                    </Link>
                                                </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    {(movieDetail.trailer_link1 != null) ?
                        <div className='card mb-2'>
                            <div className='card-header'>{movieDetail.moviename} Telugu Movie Trailer</div>
                            <div className='card-body'>
                                <YouTube videoId={extractVideoIdFromUrl(movieDetail.trailer_link1)} opts={opts} />
                            </div>
                        </div>
                        : ''
                    }

                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Upcoming Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body p-0'>
                            <ul className="list-group rounded-0">

                                {upcomingMovies.map((movie, index) => (
                                    <li className="list-group-item">
                                        <div key={index} className="d-flex">
                                            <div className="flex-shrink-0">
                                                <figure>
                                                    <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                        <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='img-fluid thumbImg2'  decoding="async" />
                                                    </Link>
                                                </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`}  >{CommonFunctions.truncateText(movie.moviename,20)}</Link></h3>
                                                <p className='fs-6 mb-0 text-truncate'> <small title={movie.genre}>{CommonFunctions.truncateText(movie.genre, 25)}</small></p>
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.getDateFormat(movie.releasedate)}</small></p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    
                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1"><i className="bi bi-cake2-fill"></i> Celebrity Today Birthday's</h2>
                        </div>
                        <div className='card-body '>
                            <Slider {...settings2} className="mb-2">
                                {celebsBdays.map((movie, index) => (
                                    <div key={index}>
                                        <figure>
                                            <img src={imageUrl + "/images/cast/" + (movie.cast_name).toLowerCase().replace(/[ ,_\/]/g, '-') + "_poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.cast_name} title={movie.cast_name} className='img-fluid'  decoding="async" />
                                        </figure>
                                        <h3  className='fs-6 mb-0 text-truncate'>{movie.cast_name}</h3>                                        
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>

                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Box Office Collections Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body p-0'>
                            <ul className="list-group rounded-0">

                                {boxOffice.map((movie, index) => (
                                    <li className="list-group-item">
                                        <div key={index} className="d-flex">
                                            <div className="flex-shrink-0">
                                                <figure>
                                                    <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                        <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='img-fluid thumbImg2'  decoding="async" />
                                                    </Link>
                                                </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename,25)}</Link></h3>
                                                <p className='fs-6 mb-0 text-truncate'> <small title={movie.genre}>{CommonFunctions.truncateText(movie.genre,25)}</small></p>
                                                <p className='fs-6 mb-0 text-truncate'> <small className='badge bg-primary rounded-pill'>{movie.box_office} Crores</small></p>
                                                <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="18" 
                                                        transition
                                                        allowFraction
                                                    />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </React.Fragment>
            }
            </div>
    <Footer />
    </React.Fragment>
  )
}

export default Detailspage