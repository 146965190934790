import React, { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'datatables.net';
import CommonFunctions from '../components/CommonFunctions';


const CelebrityMovieList = ({ data }) => {
  const tableRef = useRef();

  useEffect(() => {
    // Initialize DataTable
    const table = $(tableRef.current).DataTable({
      data,
      columns: [
        { title: 'Name', data: 'moviename' },
        { title: 'Release Date', data: 'releasedate', render: (releasedate) => CommonFunctions.getNewDateFormat(releasedate) },
        { title: 'Name', data: 'genre' },
        { title: 'Box Office Collection', data: 'totalBoxAmt' },
        // Add more columns based on your API response
      ],
    });

    // Destroy DataTable on component unmount to prevent memory leaks
    return () => {
      table.destroy();
    };
  }, [data]);



  return (
    <div className='table-responsive'>
      <table ref={tableRef} className="table table-bordered" width="100%" />
    </div>
  );
};

export default CelebrityMovieList;