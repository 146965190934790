import React from "react";
import { Helmet } from 'react-helmet'
import { Link } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Footer from "./Footer";

const TermsConditions = () => {
    return(
        <React.Fragment>
            <Helmet>
            <title>Terms &amp; Conditions  | Chalanachitralu</title>
        </Helmet>
        <div className='ht-adj'>
            <NavigationBar />
            <nav aria-label="breadcrumb" className='mt-2'>
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
                        <li className="breadcrumb-item active  text-info" aria-current="page">Terms &amp; Conditions</li>
                    </ol>
                </div>
            </nav>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card">
                            <div className="card-body">
                            <h1 className="fs-2 mt-0 mb-4">Terms &amp; Conditions</h1> 
                                <p>
                                    Welcome to chalanachitralu.com, the complete portal about Telugu Movies cast crew and Songs. If you use this website, you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our Privacy Policy govern chalanachitralu.com's relationship with you in relation to this website. The term "chalanachitralu.com" or "us" or "we" refers to the members of the website who are located in various parts of the globe and actively contribute to the database. The term "you" refers to the user or viewer of this website.
                                </p>
                                <p>
                                    The use of this website is subject to the following terms of use:
                                </p>
                                <ul>
                                    <li>The content of the pages of this website is for your general information and use only. It is subject to change without notice.</li>
                                    <li>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered in this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</li>
                                    <li>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any services or information available through this website meet your specific requirements.</li>
                                    <li>This website contains materials which are owned by or licensed by third parties. These material includes, but are not limited to, audio sample, video clip, movie poster, trailers, movie title appearance and movie title graphics. We acknowledge the rights of the copyright owners of such material, and are committed to ensure that the materials are used only for informational / promotional purposes.</li>
                                    <li>This website also contains materials which are owned by or licensed to us. These material include, but are not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</li>
                                    <li>All trademarks reproduced in this website, which are not the property of, or licensed to the operator, are acknowledged on the website.</li>
                                    <li>Unauthorised use of this website may give to a claim for damages and/or be a criminal offence.</li>
                                    <li>From time to time, this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</li>
                                    <li>You may not create a link to this website from another website or document without chalanachitralu.com's prior written consent, unless it is used purely for information purposes, and not for any commercial purposes, or documents / website containing commercial material. Linking this website to any other website or document for information purposes (including educational purposes) must have a valid link to this website.</li>                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </React.Fragment>
    )
}

export default TermsConditions