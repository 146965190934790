import React from 'react'

const KeywordsTag = () => {

    const currentYear = new Date().getFullYear();

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
      ];
    
      const currentMonthIndex = new Date().getMonth();
      const currentMonth = months[currentMonthIndex];


  return (
    <React.Fragment>
        <div className='mt-4 py-3'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <div className='footerGenres my-2 text-white'>
                    <h3 className='m-0 pb-2'><i class="bi bi-tags"></i> Tags</h3>
                    <span>Telugu Movies</span>
                    <span>Telugu Movies {currentYear}</span>
                    <span>Telugu Movies releasing in {currentMonth} {currentYear}</span>
                    <span>Telugu Movies List</span>
                    <span>Telugu Movies List {currentYear}</span>
                    <span>Telugu Movies on OTT</span>
                    <span>Telugu Movies List Old</span>
                    <span>Telugu Movies ratings</span>
                    <span>Telugu Movies reviews</span>
                    <span>Telugu Movies {currentYear} IMDb</span>
                    <span>Chalanachitralu</span>
                    <span>Chalanachitralu.com</span>
                    <span>Chalanachitralu website</span>
                    <span>Chalanachitralu ratings</span>
                    <span>Telugu Celebrities</span>
                    <span>Telugu Celebrities Birthdays</span>
                    <span>Telugu Celebrities Birthdays Today</span>
                    <span>Telugu Celebrities Birthdays in {currentMonth}</span>
                    <span>Telugu Celebrities Birthdays in {currentYear}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
    </React.Fragment>
  )
}

export default KeywordsTag