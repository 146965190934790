import React from 'react'
import { Link } from 'react-router-dom'
import ScrollToTopButton from '../components/ScrollToTopButton'

const Footer = () => {
  const allGenres = ["Action", "Adventure", "Comedy", "Drama", "Horror", "Thriller", "Family", "Sci-Fi", "Sports", "Romance", "Crime"]

  const logoSrc = "https://chalanachitralu.com/assets/images/chalanachitralu_logo.png";  

  return (
    <React.Fragment>
      <footer>
        <div className='bg-dark mt-4 py-3'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12 text-center mb-2'>
                <Link className="text-warning text-decoration-none fs-4" to="/" title="Chalanachitralu"><img src={logoSrc} alt="Chalanachitralu" /></Link>
              </div>
              <div className='col-md-12'>
                <p className='text-center text-white fs-6 mb-4'>Chalanachitralu is not just about ratings and info – we've got a bunch of cool stuff! You can explore different movie genres, watch trailers to get a sneak peek, find out which celebrities are celebrating birthdays, and even check out song lyrics. It's like a one-stop-shop for all things movies and entertainment. So, dive in, have fun exploring, and make the most of your movie experience on our website!</p>
                <hr className='text-white my-4'/>
                <div className='footerGenres my-2'>
                    {
                      allGenres.map((item) => {
                        return <span key={item}><Link to={`/genre/telugu-${item.toLowerCase()}-movies`} id={item} className="text-white">Telugu {item} Movies</Link></span>
                      })
                    }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='bg-semidark'>
          <div className='footerGenres text-center fs-6 mb-3'>
            <span><Link to="/terms-and-conditions" className='text-white text-decoration-none'>Terms &amp; Conditions</Link> </span>
            <span><Link to="/privacy-policy" className='text-white text-decoration-none'>Privacy Policy</Link></span>
            <span><Link to="/disclaimer" className='text-white text-decoration-none'>Disclaimer</Link></span>
            <span><Link to="mailto:support@chalanachitralu.com" className='text-white text-decoration-none'>Contact Us</Link></span>
          </div>
          Copyright &copy; Chalanachitralu.
          <div className='mt-4 small'>Designed and Developed by <Link to="https://github.com/meghanandan" target='_blank'>Meghanandan JB</Link></div>
        </div>
      </footer>
      <ScrollToTopButton />
    </React.Fragment>
  )
}

export default Footer