import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Apiservice from '../components/Apiservice'
import NavigationBar from '../components/NavigationBar'
import { Rating } from 'react-simple-star-rating'
import Slider from 'react-slick'
import CommonFunctions from '../components/CommonFunctions'
import { Helmet } from 'react-helmet'
import Footer from './Footer'
import CelebrityMovieList from './CelebrityMovieList'



const Celebritypage = () => {

    const [castDetails, setCastDetails] = useState([])
    const [castName, setCastName] = useState('')
    const [upcomingMovies, setUpcomingMovies] = useState([])
    const [latestMovies, setLatestMovies] = useState([])
    const [pastMovies, setPastMovies] = useState([])
    const [celebsBdays, setCelebsBdays] = useState([])
    const [boxOffice, setBoxOffice] = useState([])
    const [castBoxOffice, setCastBoxOffice] = useState([])
    const [heroMovies, setHeroMovies] = useState([])
    const [movieRating, setMovieRating] = useState('')

    useEffect(()=>{
        const getMovieCast = sessionStorage.getItem('celebrity')
        setCastName(getMovieCast)
        Apiservice.getCastDetail(getMovieCast).then(
            (response) => {
                setCastDetails(response.data.castDetail)    
                setUpcomingMovies(response.data.upcomingMovies)  
                setLatestMovies(response.data.latestMovies)  
                setPastMovies(response.data.pastMovies)  
                setCelebsBdays(response.data.celebsBdays)  
                setBoxOffice(response.data.boxOffice)  
                setCastBoxOffice(response.data.castBoxMovies)  
                setHeroMovies(response.data.castMovies)   
            }
        )
    },[])

    const tooltipArray = [
        "Terrible",
        "Terrible+",
        "Bad",
        "Bad+",
        "Average",
        "Average+",
        "Great",
        "Great+",
        "Awesome",
        "Awesome+"
      ];

      const settings1 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 12, // Number of slides to show at once
        slidesToScroll: 12, // Number of slides to scroll at a time
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 768, // adjust this value based on your design
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480, // adjust this value based on your design
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            // add more responsive settings if needed
          ],
      };      

      const settings2 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 4, // Number of slides to show at once
        slidesToScroll: 4, // Number of slides to scroll at a time
        adaptiveHeight: true
      };      

      const settings3 = {
        className: "",
        dots: true, // Show pagination dots
        infinite: true, // Infinite loop
        speed: 500, // Transition speed in milliseconds
        slidesToShow: 12, // Number of slides to show at once
        slidesToScroll: 12, // Number of slides to scroll at a time
        adaptiveHeight: true,
        responsive: [
            {
              breakpoint: 768, // adjust this value based on your design
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480, // adjust this value based on your design
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            // add more responsive settings if needed
          ],
      };      


      const imageUrl = "https://chalanachitralu.com/assets"   
      const defaultImageSrc = "https://chalanachitralu.com/assets/images/thumbs/dummy_movie-poster_cc.webp"   

      const MoviePoster = ({  }) => {
            
        if (castName) {
          const formattedMoviename = castName.toLowerCase().replace(/[ ,_\/]/g, '-');
          const imageSrc = imageUrl + `/images/cast/${formattedMoviename}_poster_cc.webp`;
      
          return (
            <img
              src={imageSrc}
              onError={(e) => {
                e.target.src = defaultImageSrc; 
              }}
              alt="default_poster"
              className='img-fluid'
            />
          );
        } else {         
          return (
            <img
              src={defaultImageSrc}
              alt="default_poster"
              className='img-fluid'
            />
          );
        }
    }

  return (
    <React.Fragment>
        <Helmet>
            <title>{`${castName}`} Tollywood Celebrity  | Chalanachitralu</title>
        </Helmet>
        <div className='ht-adj'>
        <NavigationBar />
        <nav aria-label="breadcrumb" className='mt-2'>
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/" className='text-decoration-none text-white'>Home</Link></li>
            <li className="breadcrumb-item text-white">Celebrities</li>
            <li className="breadcrumb-item active  text-info" aria-current="page">{castName}</li>
          </ol>
        </div>
      </nav>
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-9">
                    <div className="card mb-3">
                        <div className="row g-0">
                            <div className="col-md-3 col-lg-2">
                            <MoviePoster />
                            </div>
                            <div className="col-md-9 col-lg-10">
                            <div className="card-body">
                                <h1 className="card-title fs-4 text-uppercase">Hi!, I am <strong className='text-danger'>{castName}</strong>. {(castDetails != null && castDetails.cast_nickname != null) ? <React.Fragment>You can call me <strong className='text-danger'>{castDetails.cast_nickname}</strong> too.</React.Fragment> : ""}</h1>
                                {(castDetails != null) ?
                                <ul className='list-group'>
                                    <li className='list-group-item'>Full Name: <strong>{castDetails.cast_fullname}</strong></li>
                                    <li className='list-group-item'>Profession: <strong>{castDetails.cast_profession}</strong></li>
                                </ul>
                                : "" }
                            </div>
                            </div>
                        </div>
                    </div>

                    {heroMovies != null ?
                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">List of {castName} Telugu Movies</h2>
                        </div>
                        <div className='card-body '>
                          <div className='row'>
                                {heroMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                            <figure>
                                              <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                  <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}} />                                        
                                              </Link>
                                            </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.truncateText(movie.genre, 18)}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    : "" }

                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Latest Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body '>
                          <div className='row'>
                                {latestMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                            <figure>
                                              <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                  <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}} />                                        
                                              </Link>
                                            </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.truncateText(movie.genre, 18)}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    
                    {castBoxOffice != null ?
                    <div className='card mb-2'>
                      <div className='card-header'>
                        <h2 className='fs-6 my-1'>{castName} Telugu Box Office Collection Movies</h2>
                      </div>
                      <div className='card-body'>
                        <CelebrityMovieList data={castBoxOffice} />
                      </div>
                    </div>
                    : ''}

                    <div className='card mb-2'>
                        <div className='card-header'>
                            <h3 className='fs-5'>Today released past movies</h3>
                        </div>
                        <div className='card-body mb-2'>
                          <div className='row'>
                                {pastMovies.map((movie, index) => (
                                    <div className='col-md-3'  key={index}>
                                        <div className="d-flex mb-3">
                                            <div className="flex-shrink-0">
                                            <figure>
                                              <Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >
                                                  <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='' style={{width: '75px'}} />                                        
                                              </Link>
                                            </figure>
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{CommonFunctions.truncateText(movie.moviename, 13)}</Link></h3>
                                                {movie.rating != '' ?
                                                <Rating 
                                                                initialValue={movie.rating} 
                                                                readonly 
                                                                size="12" 
                                                                transition
                                                                allowFraction
                                                            />  
                                                : <small className='text-muted mb-0'>Upcoming</small> }
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.truncateText(movie.genre, 18)}</small></p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
                <div className='col-md-3'>
                  <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Upcoming Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body p-0'>
                            <ul className="list-group rounded-0">

                                {upcomingMovies.map((movie, index) => (
                                    <li className="list-group-item">
                                        <div key={index} className="d-flex">
                                            <div className="flex-shrink-0">
                                            <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='img-fluid thumbImg2' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{movie.moviename}</Link></h3>
                                                <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                                <p className='fs-6 mb-0 text-truncate'> <small>{CommonFunctions.getDateFormat(movie.releasedate)}</small></p>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Celebrity Today Birthday's</h2>
                        </div>
                        <div className='card-body '>
                            <Slider {...settings2} className="mb-2">
                                {celebsBdays.map((movie, index) => (
                                    <div key={index}>
                                        <img src={imageUrl + "/images/cast/" + (movie.cast_name).toLowerCase().replace(/[ ,_\/]/g, '-') + "_poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.cast_name} title={movie.cast_name}  className='img-fluid' />
                                        <h3  className='fs-6 mb-0 text-truncate'>{movie.cast_name}</h3>                                        
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                    <div className='card mb-2'>
                        <div className='card-header'>
                        <h2 className="fs-6 my-1">Box Office Collections Telugu Movies {CommonFunctions.currentYear}</h2>
                        </div>
                        <div className='card-body p-0'>
                            <ul className="list-group rounded-0">

                                {boxOffice.map((movie, index) => (
                                    <li className="list-group-item">
                                        <div key={index} className="d-flex">
                                            <div className="flex-shrink-0">
                                            <img src={imageUrl + "/images/thumbs/" + (movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-') + "-telugu_movie-poster_cc.webp"} onError={(e) => { e.target.src = defaultImageSrc }} alt={movie.moviename} title={movie.moviename} className='img-fluid thumbImg2' />
                                            </div>
                                            <div className="flex-grow-1 ms-3">
                                                <h3  className='fs-6 mb-0 text-truncate'><Link className='text-decoration-none' to={`/telugu-movie/${movie.id}/${(movie.moviename).toLowerCase().replace(/[ ,_\/]/g, '-')}`} >{movie.moviename}</Link></h3>
                                                <p className='fs-6 mb-0 text-truncate'> <small>{movie.genre}</small></p>
                                                <p className='fs-6 mb-0 text-truncate'> <small className='badge bg-primary rounded-pill'>{movie.box_office} Crores</small></p>
                                                <Rating 
                                                        initialValue={(movie.rating != '') ? movie.rating : '2.5'} 
                                                        readonly 
                                                        size="18" 
                                                        transition
                                                        allowFraction
                                                    />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <Footer />
    </React.Fragment>
  )
}

export default Celebritypage