import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Homepage from "./pages/Homepage";
import Detailspage from "./pages/Detailspage";
import Genrepage from "./pages/GenrePage";
import LatestMoviesPages from "./pages/LatestMoviesPages";
import NowMoviesPage from "./pages/NowMoviesPage";
import NoPage from "./pages/NoPage";
import UpcomingMoviesPage from "./pages/UpcomingMoviesPage";
import TermsConditions from "./pages/TermsConditions";
import Celebritypage from "./pages/Celebritypage";
import Disclaimerpage from "./pages/Disclaimer";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TeluguImdbMoviePage from "./pages/TeluguImdbMoviePage";
import Aboutpage from "./pages/Aboutpage";


function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route exact path="/chalanachitralu" element={<Homepage />} />
      <Route exact path="/about-us" element={<Aboutpage />} />
      <Route exact path="/telugu-movie/:id/:getmovietitle" element={<Detailspage />} />
      <Route exact path="/celebrities/:id" element={<Celebritypage />} />
      <Route exact path="/genre/:id" element={<Genrepage />} />
      <Route exact path="/now-playing" element={<NowMoviesPage />} />
      <Route exact path="/latest-telugu-movies" element={<LatestMoviesPages />} />
      <Route exact path="/movies-on-ott/:id" element={<Celebritypage />} />
      <Route exact path="/upcoming-telugu-movies" element={<UpcomingMoviesPage />} />
      <Route exact path="/telugu-imdb-movies" element={<TeluguImdbMoviePage />} />
      <Route exact path="/terms-and-conditions" element={<TermsConditions />} />
      <Route exact path="/disclaimer" element={<Disclaimerpage />} />
      <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="*" element={<NoPage />} />
    </Routes>
  </BrowserRouter>
  );
}

export default App;
